<template>
  <div class="sticky top-0 z-50 -mb-8 drop-shadow-xl dark:drop-shadow-[0_0_25px_rgba(225,225,225,0.16)]">
    <nav class="bg-white dark:bg-gray-900">
      <div class="mx-auto h-30 max-w-5xl pr-2 nav-main">
        <div class="relative flex h-28 items-start justify-between">
          <div class="flex flex-1 items-center">
            <div class="flex flex-shrink-0 items-center text-lg font-semibold text-gray-200">
              <Link href="/" class="transition-all hover:brightness-105 hover:contrast-125">
                <img :src="logo" class="h-20 w-auto" :alt="settings.name" />
              </Link>
            </div>
            <div class="mx-10 flex gap-10">
              <a href="https://www.silicon-saxony.de" class="font-semibold text-gray-400 hover:text-primary-600">HOME</a>
              <a href="/" class="font-semibold text-primary-700 hover:text-primary-800 dark:text-primary-400 dark:hover:text-primary-600">JOBS</a>
            </div>
          </div>
          <div class="hidden sm:static sm:block sm:ml-6 mt-4">
            <Button to="/arbeitgeber" variant="primary" size="sm" :active="isActive('/arbeitgeber')">
              {{ $t("components.navbar.anzeige_schalten") }}
            </Button>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { Link } from "@inertiajs/inertia-vue3"
import { Button } from "@/elements"
import { computed } from "vue"
import settings from "@/generated/settings.json"
import { useI18n } from "vue-i18n"
import { logo } from "@/utils/logos"

const slogan = computed(() => (useI18n().locale.value == "de" ? settings.slogan_de : settings.slogan_en))

const isActive = (path: string) => {
  return window && path === window.location.pathname
}
// const activeClasses = "ring-2 ring-offset-2 ring-offset-gray-800 ring-white"
</script>

<style scoped>
nav {
  clip-path: polygon(100% 0%, 100% 59px, 20.09% 100%, 0px 55.39%, 0px 0px)
}
.nav-main {
  margin-left: 15%;
}
@media (max-width: 768px) {
  .nav-main {
  margin-left: 5%;
  }
}
</style>
